import React from "react"
import Layout from "../components/layout"

const ProfilePage = location => (
  <Layout location={location}>
    <div>
      <p>Expertise areas I have been working the last years.</p>
      <p>
        <strong>Designing</strong> user experiences through interaction design. Communicate ideas through digital interfaces, enabling the user to complete their tasks of interest with the minimal effort.
      </p>
      <p>
        <strong>Building</strong> Hi-Fi prototypes to explore ideas and test designs with end-users using devices with real data. Get actionable feedback, find areas to improve, and iterate faster.
      </p>
      <p>
        <strong>Enhancing</strong> communication among stakeholders, designers, and engineers. Catch up technical constraints in advance. Develop a reliable hand-off process between design and engineering teams.
      </p>
      <p>
        <strong>Writing</strong> production-ready code to build user interfaces. Document concepts and technical specifications of components and animations as part of a design system.
      </p>
    </div>
  </Layout>
)

export default ProfilePage
